.phone-demo-people{
height:150px;

 
}

@media only screen and (max-width: 400px) {
    .phone-demo-people{
        height:100px;
        
         
        }
}