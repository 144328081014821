.bars {
    height: 3px;
    /* width: 8px;
    margin: 0 4px; */
    width: 2.5%;
    margin: 0 0.6%;
    border-radius: 10px;
    transition: height 1s,
}

@keyframes loading{
    0% {
        height: 3px;
    }
    50% {
        height: 100px;
    }
    100% {
        height: 3px;
    }
}